import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Connector = ({ name, description, domain }) => (
  <div className="rounded-md border border-white/10 p-6 flex flex-col gap-2 flex-1 text-white hover:border-lilac transition bg-white/5">
    {domain && (
      <div className="bg-white h-20 w-20 p-4 rounded-lg flex items-center">
        <img src={`https://logo.clearbit.com/${domain}`} width={64} />
      </div>
    )}
    <div className="text-lg">{name}</div>
    <div className="font-sans text-dark-grey text-sm">{description}</div>
  </div>
)

const Integrations = () => {
  return (
    <Layout variant="case-study">
      <SEO title="20+ integrations on Tactic" description="" />
      <div className="container mx-auto my-12 p-6">
        <p className="text-lilac mb-2">Integrations</p>
        <h1 className="text-3xl md:text-5xl font-bold my-4 text-white">
          Connect Tactic with all your tools
        </h1>
        <p className="text-dark-grey text-sm mb-2">
          Work in one place with every data point you need to sell at your
          fingertips.
        </p>
        <div className="border-b border-slate-800 text-white text-sm py-4 my-8">
          CRM
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <Connector
            name="Salesforce"
            description="Enrich and clean your Salesforce. Build powerful reports. Embed Tactic insights directly into Accounts. Get sophisticated reporting."
            domain="salesforce.com"
          />
          <Connector
            name="Hubspot CRM"
            description="Enrich and clean your Hubspot. Embed Tactic insights directly into Company pages. Trigger marketing and sales campaigns."
            domain="hubspot.com"
          />
        </div>
        <div className="border-b border-slate-800 text-white text-sm py-4 my-8">
          Contact Data
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <Connector
            name="ZoomInfo"
            description=""
            domain="zoominfo.com"
          />
          <Connector
            name="Lusha"
            description=""
            domain="lusha.com"
          />
          <Connector
            name="Cognism"
            description=""
            domain="cognism.com"
          />
          <Connector
            name="Apollo.io"
            description=""
            domain="apollo.io"
          />
          <Connector
            name="Kaspr"
            description=""
            domain="kaspr.io"
          />
          <Connector
            name="Custom API connector"
            description="Connect any contact data API to Tactic to create a one stop shop for enrichment."
          />
        </div>
        <div className="border-b border-slate-800 text-white text-sm py-4 my-8">
          Sales Engagement
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <Connector
            name="Outreach"
            description="Create powerful, automated personalisation"
            domain="outreach.io"
          />
          <Connector
            name="Salesloft"
            description="Enable trigger based cadences at scale"
            domain="salesloft.com"
          />
          <Connector
            name="Hubspot Sales"
            description="Sales automation with granular details about each customer"
            domain="hubspot.com"
          />
          <Connector
            name="Groove"
            description="Deeper and more customised insights for your sales outreach"
            domain="groove.co"
          />
          <Connector
            name="Apollo.io"
            description="Generate personalised email sequences directly in Apollo"
            domain="apollo.io"
          />
        </div>
        <div className="border-b border-slate-800 text-white text-sm py-4 my-8">
          Marketing Automation
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <Connector
            name="Demandbase"
            description="Supercharge your ad campaigns with better targets and external intent"
            domain="demandbase.com"
          />
          <Connector
            name="6sense"
            description="Add visible signals into your ABM campaigns"
            domain="6sense.com"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Integrations
